<template>
  <div class="row">
    <div class="col-12">
      <div
        class="reward-filter d-flex justify-content-start align-items-center"
      >
        <span class="d-flex justify-content-start align-items-center">
          <input
            v-model="currentRewardFilter"
            type="radio"
            name="rewardFilter"
            value="REWARD_LIST"
          />
          <label class="mb-0 ml-2" for="">Reward list</label>
        </span>
        <span class="d-flex justify-content-start align-items-center">
          <input
            v-model="currentRewardFilter"
            type="radio"
            name="rewardFilter"
            value="REWARDS"
          />
          <label class="mb-0 ml-2" for="">Rewards</label>
        </span>
      </div>
      <div class="card">
        <div v-if="currentRewardFilter === 'REWARD_LIST'">
          <b-table
            striped
            hover
            selectable
            responsive
            show-empty
            :items="filteredComplianceRewardEventList"
            :fields="fields"
            :busy="fetchingComplianceRewardEventList"
          >
            <template #table-busy>
              <div
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <div class="spinner-border" role="status"></div>
                <p class="mt-2 text-center"><strong>Loading...</strong></p>
              </div>
            </template>
            <template #empty>
              <div class="no-results">
                <img src="@/assets/img/no-result-found.svg" />
                <p class="text-center text-secondary">No rewards found</p>
                <p class="text-center text-secondary" style="font-size: 12px">
                  Create rewards that your drivers will have access to
                </p>
              </div>
            </template>
            <template #cell(serial)="data">
              <p class="my-0 font-weight-bold">
                {{ data.index + 1 }}
              </p>
            </template>
            <template #cell(event)="data">
              <p
                class="my-0 font-weight-bold"
                :contenteditable="data.item.isEditable"
              >
                {{ data.item.event ? data.item.event : 'N/A' }}
              </p>
            </template>
            <template #cell(point)="data">
              <p
                v-if="!data.item.isEditable"
                class="my-0 font-weight-bold"
                :contenteditable="data.item.isEditable"
              >
                {{ data.item.point ? data.item.point + ' points' : 'N/A' }}
              </p>
              <input
                class="w-20 px-3 py-2 input"
                v-if="data.item.isEditable"
                v-model="data.item.point"
                type="number"
                max="10"
                min="0"
              />
            </template>
            <template #cell(value)="data">
              <p
                class="my-0 font-weight-bold"
                :contenteditable="data.item.isEditable"
              >
                {{ data.item.value ? '₦ ' + data.item.value : 'N/A' }}
              </p>
            </template>
            <template #cell(action)="data">
              <div
                v-if="!data.item.isEditable"
                class="action d-flex justify-content-start align-items-center"
              >
                <div class="d-flex">
                  <button class="edit btn" @click="handleEdit(data.index)">
                    Edit
                  </button>
                </div>
                <div class="d-flex">
                  <button
                    class="delete"
                    type="button"
                    @click="$bvModal.show('clear-point-modal')"
                  >
                    <img class="icon" src="@/assets/img/delete_bucket.svg" />
                  </button>
                </div>
              </div>
              <div
                v-if="data.item.isEditable"
                class="action d-flex justify-content-start align-items-center"
              >
                <section class="btns">
                  <b-button
                    :disabled="data.item.isUpdating"
                    variant="primary"
                    @click="
                      updateComplianceRewardEvent(data.index, {
                        id: data.item.id,
                        points: data.item.point,
                      })
                    "
                    >{{
                      data.item.isUpdating ? 'Processing...' : 'Save'
                    }}</b-button
                  >
                  <button class="add btn" @click="cancel(data.index)">
                    Cancel
                  </button>
                </section>
              </div>
            </template>
          </b-table>
        </div>
        <div v-if="currentRewardFilter === 'REWARDS'">
          <b-table
            striped
            hover
            selectable
            responsive
            show-empty
            :items="filteredComplianceReward"
            :fields="rewardsTableFeilds"
            :busy="fetchingComplianceReward"
          >
            <template #table-busy>
              <div
                class="d-flex flex-column justify-content-center align-items-center"
              >
                <div class="spinner-border" role="status"></div>
                <p class="mt-2 text-center"><strong>Loading...</strong></p>
              </div>
            </template>
            <template #empty>
              <div class="no-results">
                <img src="@/assets/img/no-result-found.svg" />
                <p class="text-center text-secondary">No rewards found</p>
                <p class="text-center text-secondary" style="font-size: 12px">
                  Create rewards that your drivers will have access to
                </p>
              </div>
            </template>
            <template #cell(serial)="data">
              <p class="my-0 font-weight-bold">
                {{ data.index + 1 }}
              </p>
            </template>
            <template #cell(reward)="data">
              <p
                class="my-0 font-weight-bold"
                :class="{
                  'input px-3 py-2': data.item.isEditable,
                }"
                :contenteditable="data.item.isEditable"
              >
                {{ data.item.reward ? data.item.reward : 'N/A' }}
              </p>
            </template>
            <template #cell(point)="data">
              <p
                class="my-0 font-weight-bold"
                :contenteditable="data.item.isEditable"
              >
                {{ data.item.point ? data.item.point : 'N/A' }}
              </p>
            </template>
            <template #cell(action)="data">
              <div
                class="action d-flex justify-content-start align-items-center"
              >
                <div class="relative w-12 h-6 toggle">
                  <input
                    v-model="data.item.carryAction"
                    type="checkbox"
                    @change="
                      updateComplianceReward({
                        name: data.item.reward,
                        min_point: data.point,
                        status: data.item.carryAction ? 'active' : 'inactive',
                      })
                    "
                    class="absolute z-10 w-12 h-full opacity-0 cursor-pointer"
                  />
                  <label
                    :class="{
                      active: data.item.carryAction,
                    }"
                  >
                    <span :class="{ active: data.item.carryAction }" />
                  </label>
                </div>
                <div class="d-flex">
                  <button
                    class="delete"
                    type="button"
                    @click="$bvModal.show('delete-reward-modal')"
                  >
                    <img class="icon" src="@/assets/img/delete_bucket.svg" />
                  </button>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, Vue } from 'vue'
import { mapActions } from 'vuex'

export default {
  props: {
    eventRequestHandler: {
      type: Vue,
      required: false,
    },
  },
  created() {
    this.getRewardPointRate()
    this.getAllComplianceRewardEventList()
    this.getAllComplianceRewards()

    this.eventRequestHandler.$on(
      'configureRewardPoint',
      this.configureRewardPointRate
    )

    this.eventRequestHandler.$on(
      'onCreateComplianceReward',
      this.updateComplianceReward
    )
  },
  data() {
    return {
      fields: [
        {
          key: 'serial',
          label: 'S/N',
        },
        {
          key: 'event',
          label: 'Event',
        },
        {
          key: 'point',
          label: 'Point',
        },
        {
          key: 'value',
          label: 'Value',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      rewardsTableFeilds: [
        {
          key: 'serial',
          label: 'S/N',
        },
        {
          key: 'reward',
          label: 'Reward',
        },
        {
          key: 'point',
          label: 'Point',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      currentRewardFilter: ref('REWARD_LIST'),
    }
  },
  computed: {
    currentRewardPointRate() {
      return this.$store.state.rewardPoints.currentRewardPointRate
    },

    fetchingRewardPoint() {
      return this.$store.state.rewardPoints.fetchingRewardPoint
    },

    fetchingComplianceRewardEventList() {
      return this.$store.state.rewardPoints.fetchingComplianceRewardEventList
    },

    fetchingComplianceRewardHistory() {
      return this.$store.state.rewardPoints.fetchingComplianceRewardHistory
    },

    allComplianceRewardEventList() {
      return this.$store.state.rewardPoints.allComplianceRewardEventList
    },

    allComplianceRewardHistory() {
      return this.$store.state.rewardPoints.allComplianceRewardHistory
    },

    fetchingComplianceRewardEventUpdate() {
      return this.$store.state.rewardPoints.fetchingComplianceRewardEventUpdate
    },

    allComplianceReward() {
      return this.$store.state.rewardPoints.allComplianceReward
    },

    fetchingComplianceReward() {
      return this.$store.state.rewardPoints.fetchingComplianceReward
    },

    filteredComplianceRewardEventList() {
      if (this.allComplianceRewardEventList)
        return this.allComplianceRewardEventList?.map((event) => ({
          id: event.id,
          event: event.event_name,
          point: event.points,
          value: event.points * this.currentRewardPointRate,
          isEditable: ref(false),
          isUpdating: ref(false),
        }))
      return null
    },

    filteredComplianceReward() {
      if (this.allComplianceReward)
        return this.allComplianceReward?.map((reward) => ({
          id: reward.id,
          reward: reward.name,
          point: reward.min_point,
          carryAction: ref(reward.status === 'active' ? true : false),
        })).filter((reward) => reward.point)
      return null
    },

    filteredComplianceRewardHistory() {
      return this.allComplianceRewardHistory?.map((reward) => ({
        reward: reward.description,
        point: reward.points,
        carryAction: ref(false),
        isEditable: ref(false),
      }))
    },

    paginationStartIndex() {
      return this.currentPage * this.perPage - (this.perPage - 1)
    },

    paginationEndIndex() {
      return this.paginationStartIndex - 1 + this.pageSize
    },
  },
  watch: {
    currentRewardFilter() {
      if (this.currentRewardFilter === 'REWARD_LIST')
        return this.getComplianceRewardEventList()
      this.getComplianceRewards()
    },
  },
  methods: {
    ...mapActions('rewardPoints', [
      'getCurrentRewardPointRate',
      'setCurrentRewardPointRate',
      'getAllComplianceRewardEventList',
      'getAllComplianceRewardHistory',
      'updateSingleComplianceRewardEvent',
      'getAllComplianceRewards',
      'updateSingleComplianceReward',
    ]),

    handleEdit(index) {
      this.filteredComplianceRewardEventList[index].isEditable =
        !this.filteredComplianceRewardEventList[index].isEditable
    },

    cancel(index) {
      this.getComplianceRewardEventList()
      this.handleEdit(index)
    },

    handleUpdating(index) {
      this.filteredComplianceRewardEventList[index].isUpdating =
        !this.filteredComplianceRewardEventList[index].isUpdating
    },

    async getRewardPointRate() {
      try {
        const res = await this.getCurrentRewardPointRate()

        if ([200, 201].includes(res.status)) {
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async configureRewardPointRate(rate) {
      try {
        const res = await this.setCurrentRewardPointRate({
          point: 1,
          value: rate,
          currency: 'NGN',
        })

        if ([200, 201].includes(res.status)) {
          this.$bvModal.hide('configure-confirmation-modal')
          this.$bvModal.show('configure-point-success-modal')
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async getComplianceRewardEventList() {
      try {
        const res = await this.getAllComplianceRewardEventList()
        if ([200, 201].includes(res.status)) {

        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async getComplianceRewardHistory() {
      try {
        const res = await this.getAllComplianceRewardHistory()
        if ([200, 201].includes(res.status)) {

        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async updateComplianceRewardEvent(index, data) {
      this.handleUpdating(index)

      try {
        const res = await this.updateSingleComplianceRewardEvent(data)
        if ([200, 201].includes(res.status)) {
          this.handleUpdating(index)
          this.handleEdit(index)
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async getComplianceRewards() {
      try {
        const res = await this.getAllComplianceRewards()
        if ([200, 201].includes(res.status)) {
          // this.handleUpdating(index)
          // this.handleEdit(index)
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    async updateComplianceReward(data) {
      try {
        const res = await this.updateSingleComplianceReward(data)
        if ([200, 201].includes(res.status)) {
          // this.handleUpdating(index)
          // this.handleEdit(index)
          this.$bvModal.hide('create-reward-modal')
          this.$bvModal.show('create-reward-success-modal')
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },
  },
  beforeDestroy() {
    this.eventRequestHandler.$off('configureRewardPoint')
    this.eventRequestHandler.$off('onCreateComplianceReward')
  },
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';

.status {
  display: flex;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
}
.status-accepted {
  background: #0dac5c;
}
.status-pending {
  background: #cbaf02;
}
.status-queried {
  background: #4848ed;
}
.status-rejected {
  background: #c6c8c7;
}
.pending {
  color: #e29a3b;
  // background: #4848ed;
  // margin: 5px;
}
.email-status {
  display: flex;
  gap: 5px;
}
.notified {
  color: #0dac5c;
}
.locate {
  width: 184px;
  height: 42px;
  font-weight: 900;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.route {
  padding: 0 0.25rem;
  background: transparent;
  font-weight: 400;
  font-size: 12px;
  max-width: 100%;

  &__source {
    position: relative;
    &:before {
      border-left: 1px dashed #a3a3a3;
      position: absolute;
      top: 1.25rem;
      left: 0.28125rem;
      height: 70%;
      content: '';
      z-index: 0;
    }
  }
  &__source,
  &__destination {
    display: flex;
    flex-wrap: nowrap;
    color: #313533;
    .icon {
      margin-right: 0.5rem;
      width: 0.8rem;
      z-index: 2;
    }
    .text {
      /*      display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;*/
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding: 1.5rem 0;
      font-size: 1rem;
      font-weight: 500;
      white-space: nowrap;
      //  Container has to have a max-width attribute
    }
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px 0;
  img {
    height: 180px;
  }
}

.pageFooter {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 94px;

  & .desc {
    font-size: 14px;
    font-weight: 500;
    color: #131615;
  }
}

.input {
  border-radius: 6px;
  // border-color: ;
}

.edit {
  background: #4848ed;
  color: #fff;
}

.reward-filter {
  gap: 0px 24px;
  margin-bottom: 12px;

  & span {
    & label {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.action {
  gap: 0px 15px;

  & .toggle {
    position: relative;
    width: 48px;
    height: 24px;
    transition: all 200ms ease-in-out;

    & input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10px;
      opacity: 0;
      cursor: pointer;
    }

    & label {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #f9fbfa;
      // border-radius: 50%;
      border-radius: 9999px;
      // ro
      // box-shadow: inn;
      // inset: 0px;
      transition: all 200ms ease-in-out;
      border: 1px solid #e5e5ea;

      &.active {
        background: #15cd70;
      }

      & span {
        position: inherit;
        width: 24px;
        height: 100%;
        background: #ffffff;
        border-radius: 9999px;
        box-shadow: grey;
        inset: 0px;
        transition: all 200ms ease-in-out;
        border: 1px solid #e5e5ea;

        &.active {
          transform: translateX(100%);
        }
      }
    }
  }

  & :last-child {
    & .delete {
      background: transparent;
      border: 0px;
    }
  }
}
</style>
